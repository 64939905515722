import { Button } from '@invisible/ui/button'
import { DropdownMenu } from '@invisible/ui/dropdown-menu'
import { CaretDownIcon } from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { Text } from '@invisible/ui/text'
import { fontSizes, gray, purple, styled } from '@invisible/ui/themes'
import { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'

import {
  fixCase,
  handleOnDragEnd,
  initialState,
  multiplyAllCells,
  resetMenu,
  setData,
} from '../state/menuSlice'
import { menuStateSelector, ocrStateSelector } from '../state/selectors'
import { TRow } from '../types'
import { withFirebase } from './Firebase/FirebaseContext'
import HiddenTable from './HiddenTable'
import { Category } from './MenuCategory'

const Table = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding-bottom: 300px;
  overflow: auto;
  height: calc(100vh - 284px);
`

const HeaderRow = styled(Flex)`
  background-color: ${gray(4)};
  color: ${gray(12)};
  display: flex;
  margin-top: 10px;
  height: 40px;
  align-items: center;
`

const HeaderCell = styled.h1<{ type: string }>`
  width: ${({ type }) => (type === 'price' ? '10%' : '30%')};
  text-transform: uppercase;
  font-size: ${fontSizes(0)};
  padding-left: ${({ type }) => (type === 'name' ? '40px' : 0)};
`

const DropdownContainer = styled(Flex)`
  flex-direction: column;
  background: ${purple(10)};
`

export const columnMapping: Record<TRow, string[]> = {
  category: ['name', 'description', 'price'],
  item: ['name', 'description', 'price'],
  extra: ['name', 'min', 'max', 'free'],
  option: ['name', 'price'],
}

const MenuTableComponent = () => {
  const [resetTableModal, setResetTableModal] = useState(false)

  const dropdownRef = useRef<HTMLElement | null>(null)
  const copyTableRef = useRef(null)

  const dispatch = useDispatch()
  const { categories } = useSelector(menuStateSelector)
  const { activeMenu } = useSelector(ocrStateSelector)

  useEffect(() => {
    if (activeMenu) {
      if (activeMenu.rows && activeMenu?.version === 2) {
        dispatch(
          setData({
            categories: activeMenu.rows.categories,
            extras: activeMenu.rows.extras,
          })
        )
      } else {
        dispatch(
          setData({
            categories: initialState.categories,
            extras: initialState.extras,
          })
        )
      }
    }
  }, [activeMenu, dispatch])

  const handleCopy = () => {
    if (copyTableRef && copyTableRef.current !== null) {
      const table = copyTableRef.current as unknown as HTMLElement
      table.contentEditable = 'true'
      table.focus({ preventScroll: true })
      document.execCommand('selectAll')
      document.execCommand('copy')
      table.contentEditable = 'false'
    }
  }

  return (
    <>
      <Box width={1}>
        <Flex mt={3} mb={2} justifyContent='space-around' px='40px'>
          <DropdownMenu
            button={
              <Button variant='primary' size='md' iconLeft='FormatIcon'>
                <Flex alignItems='center'>
                  <Box color='white' px={2}>
                    Format All
                  </Box>
                  <CaretDownIcon width='16px' height='16px' />
                </Flex>
              </Button>
            }>
            <DropdownContainer ref={dropdownRef} width={145}>
              <Button
                variant='subtle'
                size='md'
                onClick={() => {
                  dispatch(fixCase())
                  dropdownRef.current?.remove()
                }}>
                <Flex alignItems='center' width='100%' py={1}>
                  <Box color='white' pl={2}>
                    Fix Case
                  </Box>
                </Flex>
              </Button>
              <Button
                variant='subtle'
                size='md'
                onClick={() => {
                  dispatch(multiplyAllCells())
                  dropdownRef.current?.remove()
                }}>
                <Flex alignItems='center' width='100%' py={1}>
                  <Box color='white' pl={2}>
                    Price x100
                  </Box>
                </Flex>
              </Button>
            </DropdownContainer>
          </DropdownMenu>

          <div className='mx-2 mb-1'>
            <Button variant='primary' size='md' iconLeft='RotateLeftIcon'>
              Reset
            </Button>
          </div>
          <div className='mb-1'>
            <Button variant='primary' size='md' iconLeft='CopyOutlineIcon' onClick={handleCopy}>
              Copy
            </Button>
          </div>
        </Flex>

        <Table>
          <HeaderRow>
            <HeaderCell type='name'>Name</HeaderCell>
            <HeaderCell type='description'>Description</HeaderCell>
            <HeaderCell type='price'>Price</HeaderCell>
          </HeaderRow>
          <DragDropContext onDragEnd={(data) => dispatch(handleOnDragEnd(data))}>
            <Droppable droppableId='categories' type='category'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {categories.map((category, index) => (
                    <Draggable key={category.id} draggableId={category.id} index={index}>
                      {(provided) => (
                        <Flex ref={provided.innerRef} {...provided.draggableProps}>
                          <Category
                            key={category.id}
                            category={category}
                            index={index}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </Flex>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </Box>

      <HiddenTable tableRef={copyTableRef} />

      {resetTableModal && (
        <Modal onClose={() => setResetTableModal(false)}>
          <Text p={20}>
            Are you sure you want to reset all data?
            <br /> This would delete all Categories!
          </Text>
          <Box mt='20px' style={{ textAlign: 'right' }}>
            <div className='mr-2'>
              <Button variant='secondary' size='md' onClick={() => setResetTableModal(false)}>
                Cancel
              </Button>
            </div>

            <Button
              variant='danger'
              size='md'
              onClick={() => {
                dispatch(resetMenu())
                setResetTableModal(false)
              }}>
              Confirm
            </Button>
          </Box>
        </Modal>
      )}
    </>
  )
}

export const MenuTable = withFirebase(MenuTableComponent)
