import { styled } from '@invisible/ui/themes'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { flattenedDataSelector, updateRow } from '../state/menuSlice'
import { setIsEditing } from '../state/ocrSlice'
import { menuStateSelector, ocrStateSelector } from '../state/selectors'
import { TCategory, TExtra, TItem } from '../types'
import { columnMapping } from './MenuTable'

const Container = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  resize: none;
  z-index: 10;
  padding: 10px;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const PreviewWindow: FC = () => {
  const [previewText, setPreviewText] = useState('')
  const previewWindowRef = useRef<HTMLTextAreaElement | null>(null)

  const dispatch = useDispatch()
  const { categories, extras, currentCol, currentRow } = useSelector(menuStateSelector)
  const { isEditing } = useSelector(ocrStateSelector)

  const flattenedData = useSelector(flattenedDataSelector)

  const row = useMemo(() => flattenedData[currentRow], [currentRow, flattenedData])
  const field = useMemo(() => columnMapping[row?.type]?.[currentCol], [currentCol, row])

  useEffect(() => {
    if (!row) {
      setPreviewText('')
      return
    }

    if (row.type === 'category' && row.categoryIndex !== null) {
      setPreviewText((categories[row.categoryIndex as number] as any)[field])
    }

    if (row.type === 'item' && row.categoryIndex !== null && row.itemIndex !== null) {
      setPreviewText(
        (
          (categories[row.categoryIndex as number] as TCategory).items[
            row.itemIndex as number
          ] as any
        )[field]
      )
    }

    if (row.type === 'extra' && row.extraId !== null) {
      setPreviewText((extras.find((e) => e.id === row.extraId) as TExtra as any)[field])
    }

    if (row.type === 'option' && row.extraId !== null && row.optionIndex !== null) {
      setPreviewText(
        (
          (extras.find((e) => e.id === row.extraId) as TExtra).options[
            row.optionIndex as number
          ] as any
        )[field]
      )
    }
  }, [row, categories, field, extras])

  const handlePreviewBlur = () => {
    if (!row) return
    dispatch(
      updateRow({
        field: columnMapping[row.type][currentCol],
        value: previewText,
        categoryIndex: row.categoryIndex,
        itemIndex: row.itemIndex,
        extraId: row.extraId,
        optionIndex: row.optionIndex,
        type: row.type,
        mode: 'overwrite',
      })
    )
    dispatch(setIsEditing(false))
  }

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (isEditing) return

      const { key } = event

      if (key === 'Tab') {
        event.preventDefault()

        dispatch(setIsEditing(true))
        previewWindowRef.current?.focus()
      }
    },
    [dispatch, isEditing]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <Container
      value={previewText}
      onClick={() => dispatch(setIsEditing(true))}
      onChange={(e) => setPreviewText(e.target.value)}
      onBlur={handlePreviewBlur}
      ref={previewWindowRef}
    />
  )
}

export { PreviewWindow }
