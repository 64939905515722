import { withAuth } from '@invisible/authentication/client'
import { Button } from '@invisible/ui/button'
import { styled } from '@invisible/ui/themes'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'
import { ActionCreators } from 'redux-undo'

import { Layout } from '../components/Layout'
import { columnMapping, MenuTable } from '../components/MenuTable'
import { PreviewWindow } from '../components/PreviewWindow'
import {
  addRow,
  convertRow,
  flattenedDataSelector,
  multiplyCell,
  navigate,
  updateRow,
} from '../state/menuSlice'
import { selectWord } from '../state/ocrSlice'
import { menuStateSelector, ocrStateSelector } from '../state/selectors'
const TextArea = styled.textarea`
  width: 100%;
  margin-top: 20px;
  resize: none;
  padding: 10px;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const DoordashMode: FC = () => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const dispatch = useDispatch()

  const { tableMode } = useSelector(ocrStateSelector)
  const { currentCol, currentRow } = useSelector(menuStateSelector)
  const { insertionProposal, isEditing } = useSelector(ocrStateSelector)
  const flattenedData = useSelector(flattenedDataSelector)

  useEffect(() => {
    if (insertionProposal && insertionProposal.text !== '') {
      const row = flattenedData[currentRow]

      const field = columnMapping[row.type][currentCol]

      dispatch(
        updateRow({
          field,
          value: insertionProposal.text,
          categoryIndex: row.categoryIndex,
          itemIndex: row.itemIndex,
          extraId: row.extraId,
          optionIndex: row.optionIndex,
          type: row.type,
          mode: field === 'price' ? 'overwrite' : 'append',
        })
      )

      dispatch(selectWord(null))
    }
  }, [insertionProposal])

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { key, ctrlKey } = event
      const row = flattenedData[currentRow]

      if (isEditing || !row) return
      //Price or modifier cell
      if (
        (row.type === 'item' && currentCol === 2) ||
        (row.type === 'extra' && (currentCol === 1 || currentCol === 2 || currentCol === 3)) ||
        (row.type === 'option' && currentCol === 1)
      ) {
        //If pressed key is a number
        if (!isNaN(Number(key))) {
          dispatch(
            updateRow({
              field: columnMapping[row.type][currentCol],
              value: key,
              categoryIndex: row.categoryIndex,
              itemIndex: row.itemIndex,
              extraId: row.extraId,
              optionIndex: row.optionIndex,
              type: row.type,
              mode: 'appendWithoutSpace',
            })
          )
          return
        }
      }

      //Navigation
      if (key === 'w' || key === 'ArrowUp') {
        event.preventDefault()
        dispatch(navigate({ direction: 'up' }))
      }
      if (key === 'a' || key === 'ArrowLeft') {
        event.preventDefault()
        dispatch(navigate({ direction: 'left' }))
      }
      if (key === 's' || key === 'ArrowDown') {
        event.preventDefault()
        dispatch(navigate({ direction: 'down' }))
      }
      if (key === 'd' || key === 'ArrowRight') {
        event.preventDefault()
        dispatch(navigate({ direction: 'right' }))
      }

      //Clear Cell
      if (key === 'c') {
        event.preventDefault()
        dispatch(
          updateRow({
            field: columnMapping[row.type][currentCol],
            value: '',
            categoryIndex: row.categoryIndex,
            itemIndex: row.itemIndex,
            extraId: row.extraId,
            optionIndex: row.optionIndex,
            type: row.type,
          })
        )
      }

      //Create Row Duplicate
      if (key === 'q') {
        event.preventDefault()

        if (row.type === 'category') {
          dispatch(addRow({ type: 'category' }))
        }
        if (row.type === 'item') {
          dispatch(addRow({ type: 'item', categoryId: row.parentId }))
        }
        if (row.type === 'extra') {
          dispatch(
            addRow({
              type: 'extra',
              categoryId: row.categoryId,
              itemId: row.parentId,
            })
          )
        }
      }

      //Create Row Child
      if (key === 'Q') {
        event.preventDefault()

        if (row.type === 'category') {
          dispatch(addRow({ type: 'item', categoryId: row.id }))
        }
        if (row.type === 'item') {
          dispatch(addRow({ type: 'extra', categoryId: row.parentId, itemId: row.id }))
        }
      }

      //Convert Item to Category
      if (key === '1') {
        event.preventDefault()
        dispatch(convertRow({ destination: 'category' }))
      }

      //Convert Category to Item
      if (key === '2') {
        event.preventDefault()
        dispatch(convertRow({ destination: 'item' }))
      }

      //Convert Item to Extra
      if (key === '3') {
        event.preventDefault()
        dispatch(convertRow({ destination: 'extra' }))
      }

      //Undo
      if (key === 'z' && ctrlKey) {
        event.preventDefault()

        dispatch(ActionCreators.undo())
      }

      //Redo
      if (key === 'y' && ctrlKey) {
        event.preventDefault()

        dispatch(ActionCreators.redo())
      }

      if (key === 'x') {
        event.preventDefault()

        dispatch(multiplyCell())
      }
    },
    [currentCol, currentRow, dispatch, flattenedData, isEditing]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <Layout>
      {tableMode ? (
        <>
          <PreviewWindow />
          <Box>
            <MenuTable />
          </Box>
        </>
      ) : (
        <>
          <Flex pt='2'>
            <Button
              iconLeft='CopyOutlineIcon'
              onClick={() => {
                if (textAreaRef?.current) {
                  const table = textAreaRef.current as HTMLElement
                  table.contentEditable = 'true'
                  table.focus({ preventScroll: true })
                  document.execCommand('selectAll')
                  document.execCommand('copy')
                  table.contentEditable = 'false'
                }
              }}>
              Copy
            </Button>

            <Button
              iconLeft='RotateRightIcon'
              onClick={() => {
                if (textAreaRef.current) textAreaRef.current.value = ''
              }}>
              Clear Text
            </Button>
          </Flex>
          <TextArea rows={25} ref={textAreaRef} placeholder='Enter some text...' />
        </>
      )}
    </Layout>
  )
}

export default withAuth(DoordashMode)
