import { styled } from '@invisible/ui/themes'
import { FC, Fragment, MutableRefObject } from 'react'
import { useSelector } from 'react-redux'

import { menuStateSelector } from '../state/selectors'
interface IProps {
  tableRef: MutableRefObject<null>
}

const TBody = styled.tbody`
  opacity: 0;
  z-index: -1;
  position: fixed;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const HiddenTable: FC<IProps> = ({ tableRef }) => {
  const { categories, extras } = useSelector(menuStateSelector)
  return (
    <table>
      <TBody ref={tableRef}>
        {categories.map((category) => (
          <Fragment key={category.id}>
            <tr>
              <td>Category</td>
              <td>{category.name}</td>
              <td>{category.description}</td>
            </tr>

            {category.items.map((item) => (
              <Fragment key={item.id}>
                <tr>
                  <td>Item</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{item.price}</td>
                </tr>

                {item.extras.map((extraId) => {
                  const extra = extras.find((e) => e.id === extraId)
                  return (
                    <Fragment key={extraId}>
                      <tr>
                        <td>Extra</td>
                        <td>{extra?.name}</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{extra?.min}</td>
                        <td>{extra?.max}</td>
                        <td>{extra?.free}</td>
                      </tr>

                      {extra?.options.map((option) => (
                        <tr key={option.id}>
                          <td>Option</td>
                          <td>{option.name}</td>
                          <td />
                          <td>{option.price}</td>
                        </tr>
                      ))}
                    </Fragment>
                  )
                })}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </TBody>
    </table>
  )
}

HiddenTable.displayName = 'HiddenTable'

export default HiddenTable
