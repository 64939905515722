import { gray, shadows, styled } from '@invisible/ui/themes'
import { isUndefined } from 'lodash/fp'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'

import { duplicateExtraAndUpdateData, flattenedDataSelector, updateRow } from '../state/menuSlice'
import { setIsEditing } from '../state/ocrSlice'
import { menuStateSelector, ocrStateSelector } from '../state/selectors'
import { TExtra, TFlattenedData, TItem, TPriceNode } from '../types'
import { ExtrasMenuItem } from './ExtrasMenuItem'
import { columnMapping } from './MenuTable'
import SharedExtrasModal from './SharedExtrasModal'

const Container = styled.div<{
  selected: boolean
  width?: number
  text: string
  highlightEmpty: boolean
  showPriceColoring?: boolean
}>`
  width: ${({ width }) => `${width}%` ?? '100px'};
  max-width: 300px;
  border: 1px solid ${({ selected }) => (selected ? 'blue' : 'transparent')};
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding: 0 5px;
  font-size: 11px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${({ showPriceColoring, highlightEmpty, text }) =>
    !showPriceColoring && !highlightEmpty
      ? 'transparent'
      : highlightEmpty && text === ''
      ? 'red'
      : showPriceColoring && Number(text) < 100
      ? 'yellow'
      : 'transparent'};
`

const Input = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  box-sizing: border-box;

  padding: 0 5px;
  border: none;
  font-size: 10px;

  &:focus {
    border: 1px solid blue;
    outline: none;
  }
`

const Placeholder = styled(Box)<{ empty: boolean; highlightEmpty: boolean }>`
  font-style: italic;
  color: ${({ empty, highlightEmpty, theme }) =>
    empty && highlightEmpty ? 'white' : theme.grayScale[7]};
  pointer-events: none;
`

const ExtraMenu = styled.div`
  position: absolute;
  width: 170%;
  height: fit-content;
  top: 40px;
  background-color: white;
  border-radius: 5px;
  z-index: 10;
  overflow: none;
  padding-bottom: 40px;
  box-shadow: ${shadows('medium')};
  margin-bottom: 50px;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const MenuCell: FC<{
  children: string
  selected: boolean
  width?: number
  onClick?: () => void
  handleUpdate?: (text: string) => void
  placeholder?: string
  highlightEmpty?: boolean
  showPriceColoring?: boolean
  extraMenu?: boolean
  showConfirmationModal?: boolean
  usedIn?: TItem[]
  extra?: TExtra
  field?: string
  categoryIndex?: number
  itemIndex?: number
  extraIndex?: number
  optionIndex?: number
  extras?: TExtra[]
  itemExtras?: string[]
}> = ({
  children,
  selected,
  width,
  onClick,
  placeholder,
  handleUpdate,
  highlightEmpty = false,
  extraMenu,
  showConfirmationModal = false,
  usedIn,
  extra,
  field,
  categoryIndex,
  itemIndex,
  extraIndex,
  optionIndex,
  extras,
  itemExtras,
  showPriceColoring,
}) => {
  const [text, setText] = useState(children)
  const [active, setActive] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [radioValue, setRadioValue] = useState('new')

  const dispatch = useDispatch()
  const { currentRow, currentCol } = useSelector(menuStateSelector)
  const { isEditing } = useSelector(ocrStateSelector)
  const flattenedData: TFlattenedData = useSelector(flattenedDataSelector)
  useEffect(() => {
    setText(children)
  }, [children])

  const handleCancel = () => {
    isUndefined(optionIndex)
      ? setText(extra?.[field as keyof Omit<TExtra, 'options'>] as string)
      : setText(extra!.options[optionIndex][field as keyof TPriceNode] as string)
    setModalActive(false)
  }

  const handleBlur = () => {
    dispatch(setIsEditing(false))

    extras
      ? setTimeout(() => {
          setActive(false)
        }, 500)
      : setActive(false)

    if (!showConfirmationModal) {
      handleUpdate && handleUpdate(text)
    } else if (
      (isUndefined(optionIndex) && text === extra![field as keyof Omit<TExtra, 'options'>]) ||
      (!isUndefined(optionIndex) && extra!.options[optionIndex][field! as keyof TPriceNode]) ===
        text
    ) {
      return
    } else {
      setModalActive(true)
    }
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      dispatch(setIsEditing(true))
      setActive(true)
    }
    // Remove spaces
    if (e.key === 'z') {
      const row = flattenedData[currentRow]

      dispatch(
        updateRow({
          field: columnMapping[row.type][currentCol],
          value: text.replace(/\s+/g, ''),
          categoryIndex: row.categoryIndex,
          itemIndex: row.itemIndex,
          extraId: row.extraId,
          optionIndex: row.optionIndex,
          type: row.type,
          mode: 'overwrite',
        })
      )
    }
  }

  useEffect(() => {
    if (isEditing || !selected) return
    document.addEventListener('keypress', handleKeyPress)
    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [isEditing, selected, flattenedData, currentRow, currentCol, text])

  return (
    <>
      {active ? (
        <Flex
          width={`${width}%` ?? '100px'}
          style={{ boxSizing: 'border-box', position: 'relative' }}>
          <Input
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleBlur()
              }
            }}
            onBlur={handleBlur}
            autoFocus
          />
          {extraMenu && (
            <ExtraMenu>
              {extras?.filter((e) => !itemExtras?.includes(e.id) && e.name !== '').length !== 0 ? (
                extras
                  ?.filter((e) => !itemExtras?.includes(e.id) && e.name !== '')
                  .map((e) => (
                    <ExtrasMenuItem
                      extra={e}
                      key={e.id}
                      extraIndex={extraIndex!}
                      categoryIndex={categoryIndex!}
                      itemIndex={itemIndex!}
                    />
                  ))
              ) : (
                <Box p='20px' fontSize='14px'>
                  No unused extras yet
                </Box>
              )}
            </ExtraMenu>
          )}
        </Flex>
      ) : (
        <Container
          selected={selected}
          width={width}
          text={text}
          highlightEmpty={highlightEmpty}
          showPriceColoring={showPriceColoring}
          onClick={() => {
            !isEditing && onClick?.()
          }}
          onDoubleClick={() => {
            dispatch(setIsEditing(true))
            setActive(true)
          }}>
          {placeholder && text === '' ? (
            <Placeholder empty={text === ''} highlightEmpty={highlightEmpty}>
              {placeholder}
            </Placeholder>
          ) : (
            text
          )}
        </Container>
      )}
      {showConfirmationModal && modalActive && (
        <SharedExtrasModal
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          setModalActive={setModalActive}
          extra={extra!}
          usedIn={usedIn!}
          onCancel={() => handleCancel()}
          onEdit={() => handleUpdate!(text)}
          onCreate={() => {
            dispatch(
              duplicateExtraAndUpdateData({
                categoryIndex,
                optionIndex,
                itemIndex,
                extraIndex,
                extraId: extra?.id,
                field: field!,
                value: text,
              })
            )
          }}
        />
      )}
    </>
  )
}

export default MenuCell
