import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons/faGripVertical'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gray, styled } from '@invisible/ui/themes'
import { FC, useMemo } from 'react'
import { useEffect, useState } from 'react'
import { Draggable, DraggableProvidedDragHandleProps, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'rebass'

import { deleteRow, flattenedDataSelector, makeRowActive, updateRow } from '../state/menuSlice'
import { menuStateSelector } from '../state/selectors'
import { TCategory, TFlattenedData } from '../types'
import MenuCell from './MenuCell'
import { Item } from './MenuItem'

const DragHandle = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 14px;
  left: 3px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  color: ${gray(7)};
`

const CaretIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${gray(6)};
  cursor: pointer;
  width: 10px !important;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${gray(6)};
  cursor: pointer;
  width: 10px !important;

  &:hover {
    color: ${gray(7)};
  }
`

const ItemsWrapper = styled(Flex)`
  padding-left: 20px;
  margin-left: 20px;
  border-left: 2px solid #d8bfd8;
  min-height: 1px;
`

const Row = styled(Flex)<{ hovered: boolean }>`
  background: ${({ hovered }) => hovered && '#d8bfd8'};
  transition: 0.2s background-color linear;
`
const InnerRow = styled(Flex)`
  border-bottom: 1px solid ${gray(4)};
  width: 100%;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const Category: FC<{
  category: TCategory
  index: number
  dragHandleProps?: DraggableProvidedDragHandleProps
}> = ({ category, index, dragHandleProps }) => {
  const [expanded, setExpanded] = useState(true)
  const [hovered, setHovered] = useState(false)

  const dispatch = useDispatch()
  const { currentCol, currentRow, categories } = useSelector(menuStateSelector)
  const flattenedData: TFlattenedData = useSelector(flattenedDataSelector)

  useEffect(() => {
    if (flattenedData[currentRow]?.parentId === category.id) {
      setExpanded(true)
    }
  }, [flattenedData, currentRow])

  const row = useMemo(
    () => flattenedData.findIndex((item) => item.id === category.id),
    [flattenedData]
  )

  return (
    <Flex flexDirection='column' key={category.id} width='100%'>
      <Flex
        width='100%'
        style={{ position: 'relative' }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <DragHandle {...dragHandleProps} visible={hovered}>
          <FontAwesomeIcon icon={faGripVertical} />
        </DragHandle>

        <Row pl='15px' width='100%' alignItems='center' hovered={hovered}>
          <CaretIcon
            icon={!expanded ? faAngleRight : faAngleDown}
            onClick={() => setExpanded((prev) => !prev)}
          />
          <InnerRow pl='15px'>
            <MenuCell
              selected={currentCol === 0 && category.id === flattenedData[currentRow]?.id}
              width={25}
              placeholder='No name'
              onClick={() => {
                dispatch(makeRowActive({ columnIndex: 0, rowIndex: row }))
              }}
              handleUpdate={(text) =>
                dispatch(
                  updateRow({
                    field: 'name',
                    value: text,
                    type: 'category',
                    categoryIndex: index,
                  })
                )
              }>
              {category.name}
            </MenuCell>
            <MenuCell
              selected={currentCol === 1 && category.id === flattenedData[currentRow]?.id}
              width={25}
              placeholder='No description'
              onClick={() => {
                dispatch(makeRowActive({ columnIndex: 1, rowIndex: row }))
              }}
              handleUpdate={(text) =>
                dispatch(
                  updateRow({
                    field: 'description',
                    value: text,
                    type: 'category',
                    categoryIndex: index,
                  })
                )
              }>
              {category.description}
            </MenuCell>
            <Flex alignItems='center' justifyContent='center' width='20%'>
              --
            </Flex>

            <Flex alignItems='center' ml='auto' mr='20px'>
              <DeleteIcon
                icon={faTimes}
                onClick={() => {
                  if (categories.length === 1) {
                    alert('Can not delete category')
                    return
                  }
                  dispatch(deleteRow({ type: 'category', categoryIndex: index }))
                }}
              />
            </Flex>
          </InnerRow>
        </Row>
      </Flex>

      {expanded && (
        <Droppable droppableId={`Category ${String(index)}`} type='item'>
          {(provided) => (
            <ItemsWrapper
              flexDirection='column'
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {category.items.map((item, itemIndex) => (
                <Draggable key={item.id} draggableId={item.id} index={itemIndex}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <Item
                        item={item}
                        categoryId={category.id}
                        categoryIndex={index}
                        index={itemIndex}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ItemsWrapper>
          )}
        </Droppable>
      )}
    </Flex>
  )
}

export { Category }
