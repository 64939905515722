import { Button } from '@invisible/ui/button'
import { Modal } from '@invisible/ui/modal'
import { Radio } from '@invisible/ui/radio'
import { Text } from '@invisible/ui/text'
import { Dispatch, FC, SetStateAction } from 'react'
import { Box } from 'rebass'

import { TExtra, TItem } from '../types'

interface IProps {
  setModalActive: Dispatch<SetStateAction<boolean>>
  radioValue: string
  setRadioValue: Dispatch<SetStateAction<string>>
  extra: TExtra
  usedIn: TItem[]
  onEdit: () => void
  onCreate: () => void
  onCancel?: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SharedExtrasModal: FC<IProps> = ({
  setModalActive,
  radioValue,
  setRadioValue,
  extra,
  usedIn,
  onEdit,
  onCreate,
  onCancel,
}) => (
  <Modal onClose={() => (onCancel ? onCancel() : setModalActive(false))}>
    <Text fontWeight='semibold' fontSize='18px' pb='30px'>
      Edit Shared Extra
    </Text>
    <Radio.Group
      name='extra'
      selected={radioValue}
      onChange={(value) => setRadioValue(value)}
      orientation='vertical'>
      <Radio label={`Create new Extra named "${extra.name}"`} value='new' />
      <Radio
        label={`Apply changes to ${usedIn?.length} Item(s) using this Extra: ${usedIn
          ?.map((item) => item.name || 'No name')
          .join(', ')}`}
        value='edit'
      />
    </Radio.Group>
    <Box mt='40px' style={{ textAlign: 'right' }}>
      <div className='mr-2'>
        <Button
          variant='secondary'
          size='md'
          onClick={() => (onCancel ? onCancel() : setModalActive(false))}>
          Cancel
        </Button>
      </div>

      <Button
        variant='primary'
        size='md'
        onClick={() => {
          if (radioValue === 'edit') {
            onEdit()
          } else {
            onCreate()
          }
          setModalActive(false)
        }}>
        Confirm
      </Button>
    </Box>
  </Modal>
)

export default SharedExtrasModal
