import { format } from '@invisible/ui/helpers'
import { Text } from '@invisible/ui/text'
import { gray, shadows, styled } from '@invisible/ui/themes'
import { compact } from 'lodash/fp'
import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'rebass'

import { selectExtra } from '../state/menuSlice'
import { menuStateSelector } from '../state/selectors'
import { TExtra, TItem } from '../types'
const { formatCents } = format

const Container = styled(Flex)`
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 0 5px;
  font-size: 13px;
  height: 40px;
  font-size: 12px;

  .options {
    font-size: 10px;
    font-style: italic;
  }

  .modal {
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 100%;
    width: 200px;
    height: fit-content;
    background-color: white;
    border-radius: 5px;
    z-index: 10;
    display: none;
    padding: 5px 20px;
    padding-bottom: 15px;
    box-shadow: ${shadows('small')};
  }

  .name {
    font-size: 14px;
    font-weight: bold;
    color: #1f2937;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .sub-header {
    font-weight: bold;
    color: #374151;
    margin: 15px 0 7px 0;
  }

  &:hover {
    background: ${gray(4)};
    .modal {
      display: flex;
    }
  }
`

// eslint-disable-next-line @typescript-eslint/ban-types
const ExtrasMenuItem: FC<{
  extra: TExtra
  extraIndex: number
  categoryIndex: number
  itemIndex: number
}> = ({ extra, categoryIndex, itemIndex, extraIndex }) => {
  const dispatch = useDispatch()
  const { categories } = useSelector(menuStateSelector)

  const usedIn = useMemo(
    () =>
      compact(
        categories.reduce<TItem[]>(
          (acc, curr) => [
            ...acc,
            curr.items.find((item) => item.extras.includes(extra.id)) as TItem,
          ],
          []
        )
      ),
    [categories, extra.id]
  )

  return (
    <Container
      onClick={() =>
        dispatch(
          selectExtra({
            categoryIndex,
            itemIndex,
            extraIndex,
            extraId: extra.id,
          })
        )
      }>
      <Text>{extra.name}</Text>
      <Text className='options'>{extra.options.length} Options </Text>

      <div className='modal'>
        <Text className='name'>{extra.name}</Text>
        <Text className='sub-header'>
          {extra.options.length} {extra.options.length === 1 ? 'Option' : 'Options'}{' '}
        </Text>
        <Flex>
          {extra.options
            .map(
              (option) =>
                `${option.name || 'No name'} (${
                  option.price ? formatCents(Number(option.price)) : 'No price'
                })`
            )
            .join(', ')}
        </Flex>
        <div className='sub-header'>Used In</div>
        <div>{usedIn.map((item) => item.name || 'No name').join(', ')}</div>
        <div className='sub-header'>Modifier Settings</div>
        <div>
          <span>
            {extra.min || 0} Min, {extra.max || 0} Max, {extra.free || 0} Free
          </span>
        </div>
      </div>
    </Container>
  )
}

export { ExtrasMenuItem }
