import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons/faGripVertical'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gray, styled } from '@invisible/ui/themes'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'rebass'

import {
  addRow,
  deleteRow,
  duplicateExtraWithNewOption,
  flattenedDataSelector,
  makeRowActive,
  updateRow,
} from '../state/menuSlice'
import { menuStateSelector, ocrStateSelector } from '../state/selectors'
import { TExtra, TFlattenedData, TItem } from '../types'
import MenuCell from './MenuCell'
import MenuOption from './MenuOption'
import SharedExtrasModal from './SharedExtrasModal'

const CaretIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${gray(6)};
  cursor: pointer;
  width: 10px !important;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${gray(6)};
  cursor: pointer;

  &:hover {
    color: ${gray(7)};
  }
`

const DragHandle = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 14px;
  left: 3px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  color: ${gray(7)};
`
const Row = styled(Flex)<{ hovered: boolean }>`
  background: ${({ hovered }) => hovered && 'lightgray'};
  transition: 0.2s background-color linear;
  position: relative;
  padding-left: 20px;
`

const InnerRow = styled(Flex)`
  border-bottom: 1px solid ${gray(4)};
  width: 100%;
`

const Options = styled.div`
  margin-left: 20px;
  border-left: 2px solid lightgray;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const Extra: FC<{
  extra: TExtra
  categoryId: string
  categoryIndex: number
  itemId: string
  itemIndex: number
  index: number
  dragHandleProps?: DraggableProvidedDragHandleProps
  itemExtras: string[]
}> = ({ itemExtras, extra, itemId, index, dragHandleProps, categoryIndex, itemIndex }) => {
  const [expanded, setExpanded] = useState(true)
  const [hovered, setHovered] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [radioValue, setRadioValue] = useState('new')

  const dispatch = useDispatch()
  const { categories, currentCol, currentRow, extras } = useSelector(menuStateSelector)
  const { isEditing } = useSelector(ocrStateSelector)
  const flattenedData: TFlattenedData = useSelector(flattenedDataSelector)

  const row = useMemo(
    () => flattenedData.findIndex((e) => e.id === extra.id && e.parentId === itemId),
    [extra.id, flattenedData, itemId]
  )

  const usedIn = useMemo(
    () =>
      categories.reduce<TItem[]>((acc, curr) => {
        curr.items.map((item) => {
          if (item.extras.includes(extra.id)) {
            acc.push(item)
          }
        })
        return acc
      }, []),
    [categories, extra.id]
  )

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (isEditing) return
      if (
        flattenedData[currentRow]?.itemId !== itemId &&
        flattenedData[currentRow]?.parentId !== itemId
      )
        return
      switch (event.key) {
        case 'q': {
          if (
            flattenedData[currentRow].type === 'option' &&
            flattenedData[currentRow].extraId === extra.id
          ) {
            if (usedIn.length > 1) {
              setModalActive(true)
            } else {
              dispatch(addRow({ type: 'option', extraId: extra.id }))
            }
          }
          break
        }

        case 'Q':
          if (
            flattenedData[currentRow].type === 'extra' &&
            flattenedData[currentRow].id === extra.id
          ) {
            if (usedIn.length > 1) {
              setModalActive(true)
            } else {
              dispatch(addRow({ type: 'option', extraId: extra.id }))
            }
          }
          break

        default:
          break
      }
    },
    [isEditing, flattenedData, currentRow, itemId, extra.id, usedIn, dispatch]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <>
      <Row
        hovered={hovered}
        width='100%'
        alignItems='center'
        pl='5px'
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <DragHandle visible={hovered} {...dragHandleProps}>
          <FontAwesomeIcon icon={faGripVertical} />
        </DragHandle>
        <CaretIcon
          icon={!expanded ? faAngleRight : faAngleDown}
          onClick={() => setExpanded((prev) => !prev)}
        />
        <InnerRow pl='15px'>
          <MenuCell
            selected={
              currentCol === 0 &&
              extra.id === flattenedData[currentRow]?.id &&
              itemId === flattenedData[currentRow].parentId
            }
            width={25}
            placeholder='No name'
            showConfirmationModal={usedIn.length > 1}
            usedIn={usedIn}
            extra={extra}
            field='name'
            categoryIndex={categoryIndex}
            itemIndex={itemIndex}
            extraIndex={index}
            extras={extras}
            extraMenu
            itemExtras={itemExtras}
            onClick={() => {
              dispatch(makeRowActive({ columnIndex: 0, rowIndex: row }))
            }}
            handleUpdate={(text) =>
              dispatch(
                updateRow({
                  field: 'name',
                  value: text,
                  type: 'extra',
                  extraId: extra.id,
                })
              )
            }>
            {extra?.name}
          </MenuCell>
          <Flex alignItems='center' width='30%' justifyContent='center'>
            --
          </Flex>
          <MenuCell
            selected={
              currentCol === 1 &&
              extra.id === flattenedData[currentRow]?.id &&
              itemId === flattenedData[currentRow].parentId
            }
            width={10}
            placeholder='Min'
            showConfirmationModal={usedIn.length > 1}
            usedIn={usedIn}
            extra={extra}
            field='min'
            categoryIndex={categoryIndex}
            itemIndex={itemIndex}
            extraIndex={index}
            highlightEmpty
            onClick={() => {
              dispatch(makeRowActive({ columnIndex: 1, rowIndex: row }))
            }}
            handleUpdate={(text) =>
              dispatch(
                updateRow({
                  field: 'min',
                  value: text,
                  type: 'extra',
                  extraId: extra.id,
                })
              )
            }>
            {extra?.min}
          </MenuCell>
          <MenuCell
            selected={
              currentCol === 2 &&
              extra.id === flattenedData[currentRow]?.id &&
              itemId === flattenedData[currentRow].parentId
            }
            width={10}
            placeholder='Max'
            showConfirmationModal={usedIn.length > 1}
            usedIn={usedIn}
            extra={extra}
            field='max'
            categoryIndex={categoryIndex}
            itemIndex={itemIndex}
            extraIndex={index}
            highlightEmpty
            onClick={() => {
              dispatch(makeRowActive({ columnIndex: 2, rowIndex: row }))
            }}
            handleUpdate={(text) =>
              dispatch(
                updateRow({
                  field: 'max',
                  value: text,
                  type: 'extra',
                  extraId: extra.id,
                })
              )
            }>
            {extra?.max}
          </MenuCell>
          <MenuCell
            selected={
              currentCol === 3 &&
              extra.id === flattenedData[currentRow]?.id &&
              itemId === flattenedData[currentRow].parentId
            }
            width={10}
            placeholder='Free'
            showConfirmationModal={usedIn.length > 1}
            usedIn={usedIn}
            extra={extra}
            field='free'
            categoryIndex={categoryIndex}
            itemIndex={itemIndex}
            extraIndex={index}
            highlightEmpty
            onClick={() => {
              dispatch(makeRowActive({ columnIndex: 3, rowIndex: row }))
            }}
            handleUpdate={(text) =>
              dispatch(
                updateRow({
                  field: 'free',
                  value: text,
                  type: 'extra',
                  extraId: extra.id,
                })
              )
            }>
            {extra?.free}
          </MenuCell>
          <Flex alignItems='center' ml='auto' mr='20px'>
            <DeleteIcon
              icon={faTimes}
              onClick={() =>
                dispatch(
                  deleteRow({
                    type: 'extra',
                    categoryIndex,
                    itemIndex,
                    extraIndex: index,
                    extraId: extra.id,
                  })
                )
              }
            />
          </Flex>
        </InnerRow>
      </Row>
      {expanded && (
        <Options>
          {extra?.options.map((option, optIndex) => (
            <MenuOption
              optionId={option.id}
              extraIndex={index}
              option={option}
              optionIndex={optIndex}
              key={option.id}
              extraId={extra.id}
              itemId={itemId}
              usedIn={usedIn}
              extra={extra}
              categoryIndex={categoryIndex}
              itemIndex={itemIndex}
            />
          ))}
        </Options>
      )}

      {modalActive &&
        (flattenedData[currentRow]?.itemId === itemId ||
          flattenedData[currentRow]?.parentId === itemId) && (
          <SharedExtrasModal
            radioValue={radioValue}
            setRadioValue={setRadioValue}
            setModalActive={setModalActive}
            extra={extra}
            usedIn={usedIn}
            onEdit={() => dispatch(addRow({ type: 'option', extraId: extra.id }))}
            onCreate={() => {
              dispatch(
                duplicateExtraWithNewOption({
                  extraId: extra.id,
                  categoryIndex,
                  itemIndex,
                  extraIndex: index,
                })
              )
            }}
          />
        )}
    </>
  )
}

export { Extra }
