import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons/faGripVertical'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gray, styled } from '@invisible/ui/themes'
import { FC, useMemo } from 'react'
import { useState } from 'react'
import { Draggable, DraggableProvidedDragHandleProps, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'rebass'

import { deleteRow, flattenedDataSelector, makeRowActive, updateRow } from '../state/menuSlice'
import { menuStateSelector } from '../state/selectors'
import { TFlattenedData, TItem } from '../types'
import MenuCell from './MenuCell'
import { Extra } from './MenuExtra'

const CaretIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${gray(6)};
  cursor: pointer;
  width: 10px !important;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${gray(6)};
  cursor: pointer;

  &:hover {
    color: ${gray(7)};
  }
`

const DragHandle = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 14px;
  left: 3px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  color: ${gray(7)};
`
const Row = styled(Flex)<{ hovered: boolean }>`
  background: ${({ hovered }) => hovered && 'lightblue'};
  transition: 0.2s background-color linear;
  position: relative;
  margin-left: -20px;
  padding-left: 20px;
  width: calc(100% + 20px);
`

const InnerRow = styled(Flex)`
  border-bottom: 1px solid ${gray(4)};
  width: 100%;
`

const ExtrasWrapper = styled(Flex)`
  border-left: 2px solid lightblue;
`

// eslint-disable-next-line @typescript-eslint/ban-types
const Item: FC<{
  item: TItem
  categoryId: string
  categoryIndex: number
  index: number
  dragHandleProps?: DraggableProvidedDragHandleProps
}> = ({ item, categoryId, categoryIndex, index, dragHandleProps }) => {
  const [expanded, setExpanded] = useState(true)
  const [hovered, setHovered] = useState(false)

  const dispatch = useDispatch()
  const { currentCol, currentRow, extras } = useSelector(menuStateSelector)
  const flattenedData: TFlattenedData = useSelector(flattenedDataSelector)

  const row = useMemo(() => flattenedData.findIndex((e) => e.id === item.id), [flattenedData])

  return (
    <>
      <Row
        hovered={hovered}
        width='100%'
        alignItems='center'
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        pl='15px'>
        <DragHandle visible={hovered} {...dragHandleProps}>
          <FontAwesomeIcon icon={faGripVertical} />
        </DragHandle>
        <Flex width='100%' alignItems='center'>
          <CaretIcon
            icon={!expanded ? faAngleRight : faAngleDown}
            onClick={() => setExpanded((prev) => !prev)}
          />
          <InnerRow pl='15px'>
            <MenuCell
              selected={currentCol === 0 && item.id === flattenedData[currentRow]?.id}
              width={30}
              placeholder='No name'
              onClick={() => {
                dispatch(makeRowActive({ columnIndex: 0, rowIndex: row }))
              }}
              handleUpdate={(text) =>
                dispatch(
                  updateRow({
                    field: 'name',
                    value: text,
                    type: 'item',
                    categoryIndex,
                    itemIndex: index,
                  })
                )
              }>
              {item.name}
            </MenuCell>
            <MenuCell
              selected={currentCol === 1 && item.id === flattenedData[currentRow]?.id}
              width={30}
              placeholder='No description'
              onClick={() => {
                dispatch(makeRowActive({ columnIndex: 1, rowIndex: row }))
              }}
              handleUpdate={(text) =>
                dispatch(
                  updateRow({
                    field: 'description',
                    value: text,
                    type: 'item',
                    categoryIndex,
                    itemIndex: index,
                  })
                )
              }>
              {item.description}
            </MenuCell>
            <MenuCell
              selected={currentCol === 2 && item.id === flattenedData[currentRow]?.id}
              width={15}
              placeholder='No price'
              highlightEmpty
              showPriceColoring
              onClick={() => {
                dispatch(makeRowActive({ columnIndex: 2, rowIndex: row }))
              }}
              handleUpdate={(text) =>
                dispatch(
                  updateRow({
                    field: 'price',
                    value: text,
                    type: 'item',
                    categoryIndex,
                    itemIndex: index,
                  })
                )
              }>
              {item.price}
            </MenuCell>
            <Flex alignItems='center' ml='auto' mr='20px'>
              <DeleteIcon
                icon={faTimes}
                onClick={() =>
                  dispatch(deleteRow({ type: 'item', categoryIndex, itemIndex: index }))
                }
              />
            </Flex>
          </InnerRow>
        </Flex>
      </Row>

      {expanded && (
        <Droppable droppableId={String(index)} type={String(categoryIndex)}>
          {(provided) => (
            <ExtrasWrapper
              flexDirection='column'
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {item.extras.map((extraId, extraIndex) => {
                const extra = extras.find((e) => e.id === extraId)
                return (
                  <Draggable key={extra?.id} draggableId={extra!.id} index={extraIndex}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <Extra
                          extra={extra!}
                          categoryId={categoryId}
                          categoryIndex={categoryIndex}
                          itemIndex={index}
                          itemId={item.id}
                          index={extraIndex}
                          dragHandleProps={provided.dragHandleProps}
                          itemExtras={item.extras}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </ExtrasWrapper>
          )}
        </Droppable>
      )}
    </>
  )
}

export { Item }
