import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gray, styled } from '@invisible/ui/themes'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from 'rebass'

import {
  deleteRow,
  duplicateExtraAndDeleteOption,
  flattenedDataSelector,
  makeRowActive,
  updateRow,
} from '../state/menuSlice'
import { menuStateSelector } from '../state/selectors'
import { TExtra, TFlattenedData, TItem, TPriceNode } from '../types'
import MenuCell from './MenuCell'
import SharedExtrasModal from './SharedExtrasModal'

const Row = styled(Flex)<{ hovered: boolean }>`
  width: 100%;
  background: ${({ hovered }) => hovered && '#d0f0c0'};
  padding-left: 20px;
  border-bottom: 1px solid ${gray(4)};
`

const DeleteIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${gray(6)};
  cursor: pointer;

  &:hover {
    color: ${gray(7)};
  }
`

// eslint-disable-next-line @typescript-eslint/ban-types
const MenuOption: FC<{
  optionId: string
  extraIndex: number
  optionIndex: number
  option: TPriceNode
  extraId: string
  itemId: string
  usedIn: TItem[]
  extra: TExtra
  categoryIndex: number
  itemIndex: number
}> = ({
  optionId,
  extraIndex,
  categoryIndex,
  extra,
  itemIndex,
  optionIndex,
  option,
  extraId,
  itemId,
  usedIn,
}) => {
  const [hovered, setHovered] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [radioValue, setRadioValue] = useState('new')

  const dispatch = useDispatch()
  const { currentCol, currentRow } = useSelector(menuStateSelector)
  const flattenedData: TFlattenedData = useSelector(flattenedDataSelector)

  const optionRow = flattenedData.findIndex((e) => e.id === optionId && e.itemId === itemId)
  return (
    <Row
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      hovered={hovered}>
      <MenuCell
        selected={
          currentCol === 0 &&
          optionId === flattenedData[currentRow]?.id &&
          flattenedData[currentRow].itemId === itemId
        }
        placeholder='No name'
        width={30}
        usedIn={usedIn}
        showConfirmationModal={usedIn.length > 1}
        field='name'
        extra={extra}
        categoryIndex={categoryIndex}
        itemIndex={itemIndex}
        extraIndex={extraIndex}
        optionIndex={optionIndex}
        onClick={() => {
          dispatch(makeRowActive({ columnIndex: 0, rowIndex: optionRow }))
        }}
        handleUpdate={(text) =>
          dispatch(
            updateRow({
              field: 'name',
              value: text,
              type: 'option',
              extraId,
              optionIndex,
            })
          )
        }>
        {option.name}
      </MenuCell>
      <Flex alignItems='center' width='30%' justifyContent='center'>
        --
      </Flex>
      <MenuCell
        selected={
          currentCol === 1 &&
          option.id === flattenedData[currentRow]?.id &&
          flattenedData[currentRow].itemId === itemId
        }
        placeholder='No price'
        width={15}
        highlightEmpty
        usedIn={usedIn}
        showConfirmationModal={usedIn.length > 1}
        field='price'
        extra={extra}
        categoryIndex={categoryIndex}
        itemIndex={itemIndex}
        extraIndex={extraIndex}
        optionIndex={optionIndex}
        onClick={() => {
          dispatch(makeRowActive({ columnIndex: 1, rowIndex: optionRow }))
        }}
        handleUpdate={(text) =>
          dispatch(
            updateRow({
              field: 'price',
              value: text,
              type: 'option',
              extraId,
              optionIndex,
            })
          )
        }>
        {option.price}
      </MenuCell>
      <Flex alignItems='center' ml='auto' mr='20px'>
        <DeleteIcon
          icon={faTimes}
          onClick={() => {
            if (usedIn.length > 1) {
              setModalActive(true)
            } else {
              dispatch(deleteRow({ type: 'option', extraId: extra.id, optionIndex }))
            }
          }}
        />
      </Flex>

      {modalActive && (
        <SharedExtrasModal
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          setModalActive={setModalActive}
          extra={extra}
          usedIn={usedIn}
          onEdit={() => dispatch(deleteRow({ type: 'option', extraId: extra.id, optionIndex }))}
          onCreate={() => {
            dispatch(
              duplicateExtraAndDeleteOption({
                categoryIndex,
                extraId: extra.id,
                extraIndex,
                itemIndex,
                optionIndex,
              })
            )
          }}
        />
      )}
    </Row>
  )
}

export default MenuOption
